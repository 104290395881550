window.jQuery = $
require ('paginationjs')

import setUIStateCookie from '../helpers/cookies'
import DVLessonListSection from './lesson_list_section'

var DVLessonListSectionLearningPath,
  extend = function(child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key];
    }
    function ctor() {
      this.constructor = child;
    }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
  hasProp = {}.hasOwnProperty;

DVLessonListSectionLearningPath = (function(superClass) {
  extend(DVLessonListSectionLearningPath, superClass);

  function DVLessonListSectionLearningPath(elm) {
    var cookieVal;
    DVLessonListSectionLearningPath.__super__.constructor.call(this, elm);
    this.contentsPath = $('#learning_path_path').text();
    this.learningPathCount = parseInt($('#learning_path_count').text());
    this.cookieNameCurrentLearningPathIndex =
      'currentLearningPathIndex_' + window.DV_COURSE_ID;
    this.currentLearningPathIndex = this.learningPathCount - 1;
    if (Cookies.get(this.cookieNameCurrentLearningPathIndex)) {
      cookieVal = parseInt(Cookies.get(this.cookieNameCurrentLearningPathIndex));
      if (
        !isNaN(cookieVal) &&
        cookieVal >= 0 &&
        cookieVal < this.learningPathCount
      ) {
        this.currentLearningPathIndex = cookieVal;
      }
    }
    this.hasShown = false;
  }

  DVLessonListSectionLearningPath.prototype.show = function() {
    if ($('#diagnostic_tests').length > 0) {
      this.initDiagnosticTests();
    } else {
      if (!this.hasShown) {
        this.setUpPagination();
      }
      this.loadContents();
    }
    return (this.hasShown = true);
  };

  DVLessonListSectionLearningPath.prototype.getContentURL = function() {
    return (
      this.contentsPath +
      '/position-' +
      (parseInt(this.currentLearningPathIndex, 10) + 1)
    );
  };

  DVLessonListSectionLearningPath.prototype.setUpContents = function() {
    return this.initGenerateBtn();
  };

  DVLessonListSectionLearningPath.prototype.initGenerateBtn = function() {
    var generateButton;
    generateButton = $('input.generate_btn', this.element);
    generateButton.click(
      (function(_this) {
        return function(event) {
          return Cookies.set(_this.cookieNameCurrentLearningPathIndex, null);
        };
      })(this)
    );
    generateButton.parent('form').submit(function(event) {
      generateButton.addClass('disabled');
      generateButton.attr("disabled", true);
    });
  };

  DVLessonListSectionLearningPath.prototype.setCurrentLearningPathIndex = function(
    currentLearningPathIndex
  ) {

    this.currentLearningPathIndex = currentLearningPathIndex;
    return setUIStateCookie(
      this.cookieNameCurrentLearningPathIndex,
      currentLearningPathIndex
    );
  };

  DVLessonListSectionLearningPath.prototype.initDiagnosticTests = function() {
    return this.initGenerateBtn();
  };

  DVLessonListSectionLearningPath.prototype.setUpPagination = function() {
    return $('.pagination_container', this.element).pagination({
      dataSource: [...Array(this.learningPathCount).keys()],
      callback: (data) => {
        this.onPaginationLinkClick(data[0])
      },
      pageNumber: this.currentLearningPathIndex + 1,
      pageSize: 1
    })
  };

  DVLessonListSectionLearningPath.prototype.onPaginationLinkClick = function(pageIndex) {
    this.setCurrentLearningPathIndex(pageIndex);
    return this.loadContents();
  };

  return DVLessonListSectionLearningPath;
}(DVLessonListSection));

window.DVLessonListSectionLearningPath = DVLessonListSectionLearningPath;
export default DVLessonListSectionLearningPath
