import $ from 'jquery'
import Rails from '@rails/ujs';
Rails.start()

import './ext'
import ajaxSetup from './helpers/ajax'
import { registerRoutes, dispatchRoute } from './routing'

import debug from './debug'
import navigation from './views/navigation'
import search from './views/search'

import lessons from './views/lessons'
import orientation from './views/pages/orientation'

$.ajaxSetup(ajaxSetup)

registerRoutes({
  'lessons/index': lessons,
  'pages/dv_orientation': orientation
})

$(function() {
  if(document.debugMode) {
    debug()
  }

  navigation();
  search();

  dispatchRoute($('#route').val())
});



