export default () => {
  let hotkeys = {
    // alt+d
    8706: function() {
      $('.debug').toggle();
    }
  };

  $(document).keypress(function(e) {
    if(hotkeys[e.which]) {
      hotkeys[e.which]();
    }
  });

  $('.toggle_debug').click(function() {
    $('.debug').toggle();
    return false;
  });
}