export default function DVLessonListSection(elm) {
  this.element = elm;
  this.contents = $('.section_body_contents', elm);
  this.contentsPath = null;
  this.list_request = null;
  this.shouldShowItemsWithAnimation = false;
  this.currentItemIndex = 0;
  this.showNextItemAnimationID = -1;
}

DVLessonListSection.prototype.init = function() {};

DVLessonListSection.prototype.show = function() {
  return this.element.show();
};

DVLessonListSection.prototype.getContentURL = function() {
  throw new Error('This method needs to be overriden');
};

DVLessonListSection.prototype.loadContents = function() {
  this.contents.hide();
  this.showLoadingIndicator();
  return (this.list_request = $.ajax({
    url: this.getContentURL(),
    beforeSend: (function(_this) {
      return function() {
        if (_this.list_request !== null) {
          return _this.list_request.abort();
        }
      };
    }(this)),
    success: (function(_this) {
      return function(response, status, xhrRequest) {
        var contentType;
        contentType = xhrRequest.getResponseHeader('Content-type');
        if (contentType.indexOf('json') !== -1) {
          return (window.location.href =
            response['data']['resultData']['redirect_url']);
        } else {
          _this.hideLoadingIndicator();
          _this.contents.html(response);
          _this.setUpContents();
          return _this.contents.show();
        }
      };
    }(this))
  }));
};

DVLessonListSection.prototype.setUpContents = function() {
  this.contents.find('a.toggle_btn_anchor').click(
    (function(_this) {
      return function(event) {
        var f, form, lessonItem, select;
        event.preventDefault();
        lessonItem = $(event.target).closest('.lesson_card');
        form = $('form.options', lessonItem)[0];
        select = $('select', form)[0];
        f = function(event) {
          if (event.target === form || event.target === select) {
            return event.stopImmediatePropagation();
          } else {
            $(lessonItem).off('mouseleave');
            $('body').off('mouseup');
            return $(form).hide();
          }
        };
        $(lessonItem).on('mouseleave', f);
        $('body').on('mouseup', f);
        return $(form).show();
      };
    })(this)
  );
  if (this.shouldShowItemsWithAnimation) {
    return this.showItemsInSequence();
  }
};

DVLessonListSection.prototype.showItemsInSequence = function() {
  this.currentItemIndex = 0;
  this.hideItems();
  return this.showNextItem();
};

DVLessonListSection.prototype.showNextItem = function() {
  var item, items;
  items = $('.lesson_item', this.contents);
  if (this.currentItemIndex < items.length) {
    item = $(items[this.currentItemIndex++]);
    item.css('visibility', 'visible');
    return window.requestAnimationFrame(this.showNextItem.bind(this));
  } else {
  }
};

DVLessonListSection.prototype.hideItems = function() {
  return $('.lesson_item', this.contents).css('visibility', 'hidden');
};

DVLessonListSection.prototype.showLoadingIndicator = function() {
  var cx, cy, indicator, sectionBody;
  sectionBody = $('.section_body', this.element);
  indicator = $('.loading_indicator', this.element);
  cx = (sectionBody.width() - indicator.width()) / 2;
  cy = (sectionBody.height() - indicator.height()) / 2;
  indicator.css('left', Math.floor(cx + 10) + 'px');
  indicator.css('top', Math.floor(cy) + 'px');
  return indicator.css('display', 'block');
};

DVLessonListSection.prototype.hideLoadingIndicator = function() {
  return $('.loading_indicator', this.element).css('display', 'none');
};
