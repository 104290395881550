let routes = { }

export function registerRoutes(_routes) {
  routes = _routes
}

export function dispatchRoute(path) {
  if(typeof routes[path] === 'function') {
    routes[path]()
  }
}