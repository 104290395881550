
var global_feedback_ojbect;

// Show a dialog to send feedback to our customer support
function showFeedbackDialog(extra_info) {
  global_feedback_ojbect.init();

  if (extra_info) {
    var t = $('#feedback_dialog .meta .extra_info');
    t.val( t.val() + '\n' + extra_info);
  }

  global_feedback_ojbect.openFeedback();
}

(function($) {
  $(document).ready( function() {
    // link to show feedback dialog
    if ($('.popup_feedback').length>0) {
      feedback.init();
    };

    // inner of iframe window
    if ($('#feedback_dialog_inner').length>0) {
      feedback.init();
    }

    global_feedback_ojbect = feedback;
  });

  var feedback = {
    initialized: false,

    // ================================
    // initialize
    // ================================
    init: function() {
      if (this.initialized) return true;

      var self = this;
      $('.popup_feedback').click(function(){
        self.openFeedback();
        return false;
      });

      $('#feedback_dialog .close_dialog, #feedback_dialog .overlay').click( function() {
        self.closeFeedback();
        return false;
      });

      $('#feedback_dialog_inner .close_feedback').click(function(){
        self.sendCloseMessage();
        return false;
      });

      $('#feedback_dialog_inner .submit').click(function(){
        self.submitFeedback();
        return false;
      });

      this.initialized = true;
    },

    // ================================
    // Actions
    // ================================
    openFeedback: function() {
      var url = $('#feedback_dialog .feedback_url').text();
      if (url.indexOf("?")>=0)
        url += "&";
      else
        url += "?";

      url += "comment_history=" + encodeURIComponent($('#feedback_dialog .meta .extra_info').val());
      url += "&lesson_id=" + encodeURIComponent($('#feedback_dialog .meta .lesson_id').text());

      $('#feedback_dialog iframe').attr('src', url);
      $('#feedback_dialog').show();
    },

    submitFeedback: function() {
      // $('#feedback_dialog_inner input.comment_history').val(window.parent.$('#feedback_dialog .meta .extra_info').val());
      // $('#feedback_dialog_inner input.lesson_id').val(window.parent.$('#feedback_dialog .meta .lesson_id').text());
      $('#feedback_dialog_inner form').submit();
    },

    sendCloseMessage: function() {
      // In iframe, send a message to close dialog to the parent window
      window.parent.$('#feedback_dialog .close_dialog').trigger('click');
    },

    closeFeedback: function() {
      $('#feedback_dialog').hide();
    }

  };
})(jQuery);
