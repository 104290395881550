/*globals $ */
var DebugWidget = function(id) {
  this.init($(id));
}

DebugWidget.prototype = {
  init: function(scope) {
    this.scope = scope;
  },

  applyToLessons: function() {
    this.scope.find("a.toggle_btn_anchor").click(function(e) {
      e.preventDefault();
      var lessonItem = $(e.target).closest(".lesson_card");
      var form = $("form.options", lessonItem)[0];
      var select = $("select", form)[0];

      var f = function(e) {
        if(e.target === form || e.target === select) {
          e.stopImmediatePropagation();
        }
        else {
          $(lessonItem).off("mouseleave");
          $("body").off("mouseup"); // WTF???
          $(form).hide();
        }
      }
      $(lessonItem).on("mouseleave", f);
      $("body").on("mouseup", f);
      $(form).show();
     });
   }
};

export default DebugWidget
