import setUIStateCookie from '../helpers/cookies'

var DVGroupBySelector,
  DVLessonFinder_Select,
  GROUPBY_CRITERION_LEVEL,
  GROUPBY_CRITERION_STATUS,
  GROUPBY_CRITERION_TOPIC,
  IS_LTE_IE7,
  STATUS_LABEL_DONE,
  STATUS_LABEL_DONE_ELSEWHEN,
  STATUS_LABEL_IN_PROGRESS,
  STATUS_LABEL_READY,
  STATUS_LABEL_FAILED,
  extend = function(child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key];
    }
    function ctor() {
      this.constructor = child;
    }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
  hasProp = {}.hasOwnProperty;

GROUPBY_CRITERION_TOPIC = 'topic';

GROUPBY_CRITERION_LEVEL = 'level';

GROUPBY_CRITERION_STATUS = 'progress_status';

STATUS_LABEL_READY = 'Not Started';

STATUS_LABEL_IN_PROGRESS = 'In Progress';

STATUS_LABEL_FAILED = 'Failed';
STATUS_LABEL_DONE = 'Done';

STATUS_LABEL_DONE_ELSEWHEN = 'Done in Another Period';

IS_LTE_IE7 = false;

export var DVLessonFinder = (function() {
  function DVLessonFinder(elm) {
    this.element = elm;
    this.contents = $('#section_all .section_body_contents');
    this.cookieNameGroupByCriterion = 'groupByCriterion_' + window.DV_COURSE_ID;
    this.cookieNameSearchQuery = 'searchQuery_' + window.DV_COURSE_ID;
    this.criterion = Cookies.get(this.cookieNameGroupByCriterion)
      ? Cookies.get(this.cookieNameGroupByCriterion)
      : GROUPBY_CRITERION_TOPIC;
    this.selector = new DVGroupBySelector('#group_by');
    this.query = Cookies.get(this.cookieNameSearchQuery)
      ? Cookies.get(this.cookieNameSearchQuery)
      : '';
    this.field = $('.field', elm);
    this.clearBtn = $('a.clear', elm);
    this.matchMessage = $('.match_message', elm);
    this.matchMessages = {
      zero: $('#msg_search_no_matches').text(),
      one: $('#msg_search_one_match').text(),
      other: $('#msg_search_x_matches').text()
    };
    this.init();
  }

  DVLessonFinder.prototype.init = function() {
    this.selector.val(this.criterion, false);
    this.selector.bind(
      'change',
      (function(_this) {
        return function(event) {
          return _this.performGroupBy();
        };
      }(this))
    );
    this.field.val(this.query);
    if (IS_LTE_IE7) {
      $('#search button').on(
        'click',
        (function(_this) {
          return function(event) {
            return _this.performSearch();
          };
        }(this))
      );
      $(document).on(
        'keyup',
        (function(_this) {
          return function(event) {
            if (event && event.keyCode === 13) {
              return _this.performSearch();
            }
          };
        }(this))
      );
    } else {
      this.field.on(
        'keyup change',
        (function(_this) {
          return function(event) {
            return _this.performSearch();
          };
        }(this))
      );
    }
    this.clearBtn.click(
      (function(_this) {
        return function(event) {
          return _this.clearField();
        };
      })(this)
    );

    return $(window).on('unload', () => {
      var criterion, query;
      criterion = this.criterion ? this.criterion : '';
      setUIStateCookie(this.cookieNameGroupByCriterion, criterion);
      query = this.query ? this.query : '';
      setUIStateCookie(this.cookieNameSearchQuery, query);
    });
  };

  DVLessonFinder.prototype.perform = function() {
    this.performGroupBy(false);
    this.performSearch(false);
    return this.updateCountOnEachGroup();
  };

  DVLessonFinder.prototype.performGroupBy = function(
    shouldUpdateCountOnEachGroup
  ) {
    var c, criterionElmSelector, itemGroup, itemGroupArray, j, len;
    if (shouldUpdateCountOnEachGroup == null) {
      shouldUpdateCountOnEachGroup = true;
    }
    $(document).trigger('lessonsFiltered');
    this.criterion = this.selector.value;
    c = this.criterion;
    criterionElmSelector = null;
    if (c === GROUPBY_CRITERION_STATUS) {
      criterionElmSelector = '.progress_status';
    } else if (c === GROUPBY_CRITERION_LEVEL) {
      criterionElmSelector = '.meta .level';
    } else {
      criterionElmSelector = '.meta .topic';
    }
    itemGroupArray = this.createItemGroups(criterionElmSelector, c);
    this.contents.contents().detach();
    for (j = 0, len = itemGroupArray.length; j < len; j++) {
      itemGroup = itemGroupArray[j];
      this.contents.append(this.createItemGroupElement(itemGroup));
    }
    if (shouldUpdateCountOnEachGroup) {
      return this.updateCountOnEachGroup();
    }
  };

  DVLessonFinder.prototype.createItemGroups = function(
    criterionElmSelector,
    criterion
  ) {
    var dic, self;
    dic = {};
    self = this;
    $('#section_all .list_item').each(function() {
      var criterionValue, itemGroup, key;
      criterionValue = $.trim($(criterionElmSelector, this).text());
      key = encodeURIComponent(criterionValue);
      if (!dic.hasOwnProperty(key)) {
        itemGroup = {
          name: criterionValue,
          items: []
        };
        dic[key] = itemGroup;
      }
      return dic[key].items.push(self.createItem(this));
    });
    return this.processItemGroups(dic, criterion);
  };

  DVLessonFinder.prototype.createItem = function(elm) {
    var item;
    item = new Object();
    item['elm'] = elm;
    item['name'] = $.trim($('.name', elm).text());
    item['topic'] = $.trim($('.meta .topic', elm).text());
    item['subtopic'] = $.trim($('.subtopic', elm).text());
    item['level'] = parseInt($('.meta .level', elm).text());
    item['startAt'] = parseInt($('.start_at', elm).text());
    item['passAt'] = parseInt($('.pass_at', elm).text());
    return item;
  };

  DVLessonFinder.prototype.processItemGroups = function(
    itemGroupDictionary,
    criterion
  ) {
    var arr,
      itemGroup,
      itemGroupDone,
      itemGroupDoneElsewhen,
      itemGroupFailed,
      itemGroupInProgress,
      itemGroupReady,
      key,
      val;
    arr = [];
    if (criterion === GROUPBY_CRITERION_STATUS) {
      itemGroupReady = itemGroupDictionary['ready'];
      itemGroupInProgress = itemGroupDictionary['in_progress'];
      itemGroupDone = itemGroupDictionary['done'];
      itemGroupFailed = itemGroupDictionary['failed'];
      itemGroupDoneElsewhen = itemGroupDictionary['done_elsewhen'];
      if (itemGroupInProgress) {
        this.processItemGroup(itemGroupInProgress, criterion);
        arr.push(itemGroupInProgress);
      }
      if (itemGroupFailed) {
        this.processItemGroup(itemGroupFailed, criterion);
        arr.push(itemGroupFailed);
      }
      if (itemGroupReady) {
        this.processItemGroup(itemGroupReady, criterion);
        arr.push(itemGroupReady);
      }
      if (itemGroupDone) {
        this.processItemGroup(itemGroupDone, criterion);
        arr.push(itemGroupDone);
      }
      if (itemGroupDoneElsewhen) {
        this.processItemGroup(itemGroupDoneElsewhen, criterion);
        arr.push(itemGroupDoneElsewhen);
      }
    } else {
      for (key in itemGroupDictionary) {
        val = itemGroupDictionary[key];
        if (val.hasOwnProperty('items')) {
          itemGroup = val;
          this.processItemGroup(itemGroup, criterion);
          arr.push(itemGroup);
        }
      }
      arr = arr.sortOn('name');
    }
    return arr;
  };

  DVLessonFinder.prototype.groupStatuses = function() {
    var elm, statuses;
    statuses = {
      ready: STATUS_LABEL_READY,
      in_progress: STATUS_LABEL_IN_PROGRESS,
      failed: STATUS_LABEL_FAILED,
      done: STATUS_LABEL_DONE,
      done_elsewhen: STATUS_LABEL_DONE_ELSEWHEN
    };
    elm = $('.progress_status .statuses');
    if (elm.length > 0) {
      $.each(statuses, function(status) {
        var label;
        label = $.trim($('.' + status, elm).text());
        if (label) {
          return (statuses[status] = label);
        }
      });
    }
    return statuses;
  };

  DVLessonFinder.prototype.processItemGroup = function(itemGroup, criterion) {
    var items, name, names;
    name = itemGroup.name;
    items = itemGroup.items;
    if (criterion === GROUPBY_CRITERION_STATUS) {
      names = this.groupStatuses();
      if (name === 'ready') {
        itemGroup.name = names[name];
        return (itemGroup.items = items.sortOn('level', 'name'));
      } else if (itemGroup.name === 'in_progress') {
        itemGroup.name = names[name];
        return (itemGroup.items = items.sortOn('startAt'));
      } else if (itemGroup.name === 'failed') {
        itemGroup.name = names[name];
        return (itemGroup.items = items.sortOn('startAt'));
      } else if (itemGroup.name === 'done') {
        itemGroup.name = names[name];
        return (itemGroup.items = items.sortOn('passAt'));
      } else if (itemGroup.name === 'done_elsewhen') {
        itemGroup.name = names[name];
        return (itemGroup.items = items.sortOn('passAt'));
      }
    } else if (criterion === GROUPBY_CRITERION_LEVEL) {
      itemGroup.name = 'Level ' + name;
      return (itemGroup.items = items.sortOn('name'));
    } else {
      return (itemGroup.items = items.sortOn('subtopic', 'level', 'name'));
    }
  };

  DVLessonFinder.prototype.createItemGroupElement = function(itemGroup) {
    var count, group, header, item, items, j, len, name, ul;
    name = itemGroup.name;
    items = itemGroup.items;
    group = $(document.createElement('div'));
    group.addClass('lesson_card_group');
    header = $(document.createElement('h3'));
    header.text(name);
    count = $(document.createElement('span'));
    count.addClass('count');
    count.appendTo(header);
    ul = $(document.createElement('ul'));
    ul.addClass('lessons')
    for (j = 0, len = items.length; j < len; j++) {
      item = items[j];
      ul.append(item.elm);
    }
    group.append(header);
    group.append(ul);
    return group;
  };

  DVLessonFinder.prototype.performSearch = function(
    shouldUpdateCountOnEachGroup
  ) {
    var cnt, msg, q;
    if (shouldUpdateCountOnEachGroup == null) {
      shouldUpdateCountOnEachGroup = true;
    }
    $(document).trigger('lessonsFiltered');
    this.query = $.trim(this.field.val());
    q = this.query;
    $(document).trigger('user:search', q);
    if (q.length > 0) {
      q = q.toLowerCase();
      cnt = 0;
      msg = '';
      $('#section_all .list_item').each(function() {
        var name, name_translation, subtopic, topic;
        name = $('.name', this)
          .text()
          .toLowerCase();
        name_translation = $('.name-translation', this)
          .text()
          .toLowerCase();
        topic = $('.meta .topic', this)
          .text()
          .toLowerCase();
        subtopic = $('.subtopic', this)
          .text()
          .toLowerCase();
        if (
          name.indexOf(q) !== -1 ||
          name_translation.indexOf(q) !== -1 ||
          topic.indexOf(q) !== -1 ||
          subtopic.indexOf(q) !== -1
        ) {
          cnt++;
          return $(this).show();
        } else {
          return $(this).hide();
        }
      });
      if (cnt > 1) {
        msg = this.matchMessages['other'].replace('0', cnt.toString());
      } else if (cnt === 1) {
        msg = this.matchMessages['one'];
      } else {
        msg = this.matchMessages['zero'];
      }
      this.matchMessage.text(msg);
      this.clearBtn.show();
      this.field.addClass('active');
    } else {
      this.clearField();
    }
    if (shouldUpdateCountOnEachGroup) {
      this.updateCountOnEachGroup();
    }
  };

  DVLessonFinder.prototype.clearField = function() {
    this.query = '';
    this.field.val('');
    this.field.removeClass('active');
    this.clearBtn.hide();
    this.matchMessage.text(' ');
    $('#section_all .list_item').show();
    return this.updateCountOnEachGroup();
  };

  DVLessonFinder.prototype.updateCountOnEachGroup = function() {
    var self;
    self = this;
    return $('.lesson_card_group', this.contents).each(function() {
      var items, matchedItems, str, strItemCount;
      items = $('.list_item', this);
      str = null;
      strItemCount = items.length.toString();
      if (self.query) {
        matchedItems = $(items).filter(':visible');
        str = matchedItems.length.toString() + ' / ' + strItemCount;
      } else {
        str = strItemCount;
      }
      return $('.count', this).text(str);
    });
  };

  return DVLessonFinder;
}());

window.DVLessonFinder = DVLessonFinder;

DVGroupBySelector = (function() {
  function DVGroupBySelector(elm) {
    this.element = $(elm);
    this.selectedOption = $('.selected_option', elm);
    this.options = $('.options', elm);
    this.value = null;
    this.init();
  }

  DVGroupBySelector.prototype.init = function() {
    this.setSelectedOptionIndex(0, false);
    this.selectedOption.bind(
      'click',
      (function(_this) {
        return function(event) {
          event.preventDefault();
          _this.showOptions();
          event.stopPropagation();
          return false;
        };
      }(this))
    );
    return $('a', this.options).bind(
      'click',
      (function(_this) {
        return function(event) {
          event.preventDefault();
          _this.selectOption(event.target);
          return false;
        };
      }(this))
    );
  };

  DVGroupBySelector.prototype.showOptions = function() {
    var optionHeight, optionsTop;
    this.options.show();
    if (!IS_LTE_IE7) {
      optionHeight = $(this.options.children('li')[0]).height();
      optionsTop = -(optionHeight * this.getSelectedOptionIndex());
      this.options.css('top', optionsTop);
    }
    return $('body').bind(
      'click',
      (function(_this) {
        return function(event) {
          return _this.hideOptions();
        };
      }(this))
    );
  };

  DVGroupBySelector.prototype.hideOptions = function() {
    $('body').unbind('click');
    return this.options.hide();
  };

  DVGroupBySelector.prototype.selectOption = function(option, shouldTrigger) {
    var prevValue;
    if (shouldTrigger == null) {
      shouldTrigger = true;
    }
    option = $(option);
    prevValue = this.value;
    this.selectedOption.find('.text').text(option.text());
    this.value = $(option)
      .closest('li')
      .attr('class');
    $('a', this.options).removeClass('selected');
    $(option).addClass('selected');
    if (this.value !== prevValue && shouldTrigger) {
      this.trigger('change');
    }
    return this.hideOptions();
  };

  DVGroupBySelector.prototype.setSelectedOptionIndex = function(
    i,
    shouldTrigger
  ) {
    var option;
    if (shouldTrigger == null) {
      shouldTrigger = true;
    }
    option = $(this.options.children('li')[i]).find('a');
    return this.selectOption(option, shouldTrigger);
  };

  DVGroupBySelector.prototype.getSelectedOptionIndex = function() {
    var i, index, j, len, option, ref;
    index = 0;
    ref = this.options.children('li');
    for (i = j = 0, len = ref.length; j < len; i = ++j) {
      option = ref[i];
      if ($('a', option).hasClass('selected')) {
        index = i;
        break;
      }
    }
    return index;
  };

  DVGroupBySelector.prototype.val = function(value, shouldTrigger) {
    var option;
    if (shouldTrigger == null) {
      shouldTrigger = true;
    }
    if (!value) {
      return this.value;
    }
    option = $('li.' + value.toLowerCase(), this.element).find('a');
    if (option) {
      return this.selectOption(option, shouldTrigger);
    } else {
      return alert('Error: Attempt to set an invalid value.');
    }
  };

  DVGroupBySelector.prototype.bind = function(eventType, handler) {
    return this.element.bind(eventType, handler);
  };

  DVGroupBySelector.prototype.trigger = function(eventType) {
    return this.element.trigger(eventType);
  };

  return DVGroupBySelector;
}());

window.DVGroupBySelector = DVGroupBySelector;

DVLessonFinder_Select = (function(superClass) {
  extend(DVLessonFinder_Select, superClass);

  function DVLessonFinder_Select(elm) {
    DVLessonFinder_Select.__super__.constructor.call(this, elm);
  }

  DVLessonFinder_Select.prototype.performGroupBy = function(
    shouldUpdateCountOnEachGroup
  ) {
    if (shouldUpdateCountOnEachGroup == null) {
      shouldUpdateCountOnEachGroup = true;
    }
    DVLessonFinder_Select.__super__.performGroupBy.call(
      this,
      shouldUpdateCountOnEachGroup
    );
    return $('.batch_selector a').on(
      'click',
      (function(_this) {
        return function(event) {
          var b, currentItemGroup, target;
          event.preventDefault();
          event.stopPropagation();
          target = $(event.target);
          b = target.hasClass('select') ? true : false;
          currentItemGroup = $('.section_body_contents .lesson_card_group');
          $('.lesson_card:visible input:checkbox', currentItemGroup).prop(
            'checked',
            b
          );
          _this.trigger('selectedLessonsChange');
          return false;
        };
      }(this))
    );
  };

  DVLessonFinder_Select.prototype.createItem = function(elm) {
    var item;
    item = new Object();
    item['elm'] = elm;
    item['name'] = $.trim($('.name', elm).text());
    item['topic'] = $.trim($('.meta .topic', elm).text());
    item['subtopic'] = $.trim($('.subtopic', elm).text());
    return item;
  };

  DVLessonFinder_Select.prototype.createItemGroupElement = function(itemGroup) {
    var group, header, item, items, j, len, lessonSelector, name, table;
    name = itemGroup.name;
    items = itemGroup.items;
    group = $(document.createElement('div'));
    group.addClass('lesson_card_group');
    header = $(document.createElement('h3'));
    header.text(name);
    lessonSelector = $('#lesson_card_group_selector div:first-child').clone();
    table = $(document.createElement('table'));
    for (j = 0, len = items.length; j < len; j++) {
      item = items[j];
      table.append(item.elm);
    }
    group.append(header);
    group.append(lessonSelector);
    group.append(table);
    return group;
  };

  DVLessonFinder_Select.prototype.updateCountOnEachGroup = function() {};

  DVLessonFinder_Select.prototype.bind = function(eventType, handler) {
    return this.element.bind(eventType, handler);
  };

  DVLessonFinder_Select.prototype.trigger = function(eventType) {
    return this.element.trigger(eventType);
  };

  return DVLessonFinder_Select;
}(DVLessonFinder));

window.DVLessonFinder_Select = DVLessonFinder_Select;
