import DVLessonListSection from './lesson_list_section'
import { DVLessonFinder } from './lesson_finder'
import setUIStateCookie from '../helpers/cookies'

var CATEGORY_GRAMMAR,
  CATEGORY_LISTENING,
  CATEGORY_READING,
  extend = function(child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key];
    }
    function ctor() {
      this.constructor = child;
    }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
  hasProp = {}.hasOwnProperty;

CATEGORY_GRAMMAR = 'grammar';

CATEGORY_LISTENING = 'listening';

CATEGORY_READING = 'reading';

export const DVLessonListSectionAll = (function(superClass) {
  extend(DVLessonListSectionAll, superClass);

  function DVLessonListSectionAll(elm) {
    DVLessonListSectionAll.__super__.constructor.call(this, elm);
    this.lessonFinder = this.createLessonFinder();
    this.contentsPath = $('#lessons_path').text();
    this.cookieNameCurrentCategory = 'currentCategory_' + window.DV_COURSE_ID;
    this.currentCategory = Cookies.get(this.cookieNameCurrentCategory)
      ? Cookies.get(this.cookieNameCurrentCategory)
      : CATEGORY_GRAMMAR;
    this.conditions = null;
    this.init();
  }

  DVLessonListSectionAll.prototype.createLessonFinder = function() {
    return new DVLessonFinder($('#lesson_finder'));
  };

  DVLessonListSectionAll.prototype.init = function() {
    DVLessonListSectionAll.__super__.init.call(this);
    return $('#category_tabs a.tab').click(
      (function(_this) {
        return function(event) {
          event.preventDefault();
          return _this.onCategoryTabClick($(event.target).closest('li'));
        };
      })(this)
    );
  };

  DVLessonListSectionAll.prototype.show = function() {
    var tab;
    DVLessonListSectionAll.__super__.show.apply(this, arguments);
    tab = $('#category_tabs li.' + this.currentCategory);
    if (tab.length == 0) {
      tab = $('#category_tabs li:first-child');
    }
    return this.onCategoryTabClick(tab);
  };

  DVLessonListSectionAll.prototype.getContentURL = function() {
    var path;
    path = this.contentsPath + '?' + $.param(this.conditions);
    return path;
  };

  DVLessonListSectionAll.prototype.setUpContents = function() {
    DVLessonListSectionAll.__super__.setUpContents.call(this);
    return this.lessonFinder.perform();
  };

  DVLessonListSectionAll.prototype.setCurrentCategory = function(
    currentCategory
  ) {
    this.currentCategory = currentCategory;
    return setUIStateCookie(this.cookieNameCurrentCategory, currentCategory);
  };

  DVLessonListSectionAll.prototype.onCategoryTabClick = function(tab) {
    tab = $(tab);
    if (tab.hasClass('current')) {
      return;
    }
    $('#category_tabs li').removeClass('current');
    this.setCurrentCategory(tab.length === 0 ? 'general' : tab.attr('class'));
    this.loadContentsWithConditions({
      category: this.currentCategory
    });
    return tab.addClass('current');
  };

  DVLessonListSectionAll.prototype.loadContentsWithConditions = function(
    conditions
  ) {
    this.conditions = conditions;
    return this.loadContents();
  };

  return DVLessonListSectionAll;
}(DVLessonListSection));

export const DVLessonListSectionAll_Select = (function(superClass) {
  extend(DVLessonListSectionAll_Select, superClass);

  function DVLessonListSectionAll_Select(elm) {
    this.hasContentsLoaded = false;
    this.contentsDeposit = $('#section_body_contents_deposit');
    this.selectedLessonCounter = $('#selected_lesson_counter');
    this.selectedLessonCounterMessages = {
      zero: $('#msg_no_lessons_selected').text(),
      one: $('#msg_one_lesson_selected').text(),
      other: $('#msg_x_lessons_selected').text()
    };
    this.cmsProjectId = $('#cms_project_id').text();
    this.queryParameters = {};
    this.selectedLessonIds = $('[data-selected-lesson-ids]').attr(
      'data-selected-lesson-ids'
    );
    if (this.selectedLessonIds) {
      this.queryParameters['lesson_ids'] = this.selectedLessonIds;
    }
    this.queryParameters['cms_project_id'] = this.cmsProjectId;
    DVLessonListSectionAll_Select.__super__.constructor.call(this, elm);
  }

  DVLessonListSectionAll_Select.prototype.init = function() {
    DVLessonListSectionAll_Select.__super__.init.call(this);
    this.lessonFinder.bind(
      'selectedLessonsChange',
      (function(_this) {
        return function(event) {
          return _this.onSelectedLessonsChange();
        };
      }(this))
    );
    $('#batch_selector a').on(
      'click',
      (function(_this) {
        return function(event) {
          var b;
          event.preventDefault();
          event.stopPropagation();
          b = $(event.target).hasClass('select') ? true : false;
          $(
            '.lesson_card:visible input:checkbox:not(:disabled)',
            _this.contents
          ).prop('checked', b);
          _this.onSelectedLessonsChange();
          return false;
        };
      }(this))
    );
    if (this.isForExternalSource()) {
      $('.select form').submit(function(event) {
        return $('input[type=submit]').attr('disabled', true);
      });
      $('#btn_cancel').click(function(event) {
        $(event.target).attr('disabled', true);
        window.close();
        return false;
      });
    }
    return this.onSelectedLessonsChange();
  };

  DVLessonListSectionAll_Select.prototype.createLessonFinder = function() {
    return new DVLessonFinder_Select($('#lesson_finder'));
  };

  DVLessonListSectionAll_Select.prototype.onCategoryTabClick = function(tab) {
    tab = $(tab);
    if (tab.hasClass('current')) {
      return;
    }
    $('#category_tabs li').removeClass('current');
    this.setCurrentCategory(tab.attr('class'));
    if (!this.hasContentsLoaded) {
      this.loadAllContentsInAdvance();
    } else {
      this.setUpContents();
    }
    return tab.addClass('current');
  };

  DVLessonListSectionAll_Select.prototype.getContentURL = function() {
    return this.contentsPath;
  };

  DVLessonListSectionAll_Select.prototype.isForExternalSource = function() {
    var ref;
    return (ref = this.getContentURL().match(/target_url/)) != null
      ? ref
      : {
          true: false
        };
  };

  DVLessonListSectionAll_Select.prototype.determineRequestVerb = function() {
    if (this.isForExternalSource()) {
      return 'POST';
    } else {
      return 'GET';
    }
  };

  DVLessonListSectionAll_Select.prototype.getQueryParameters = function() {
    return this.queryParameters;
  };

  DVLessonListSectionAll_Select.prototype.loadAllContentsInAdvance = function() {
    var itemContainer;
    if (this.hasContentsLoaded) {
      this.setUpContents();
      return;
    }
    this.contents.css('visibility', 'hidden');
    itemContainer = $('.lesson_card_container', this.contents);
    itemContainer.css('display', 'none');
    this.showLoadingIndicator();
    return $.ajax({
      url: this.getContentURL(),
      dataType: 'html',
      data: this.getQueryParameters(),
      type: this.determineRequestVerb(),
      success: (function(_this) {
        return function(html) {
          _this.hideLoadingIndicator();
          itemContainer.css('display', 'block');
          _this.contentsDeposit.html(html);
          _this.hasContentsLoaded = true;
          _this.contents.css('visibility', 'visible');
          return _this.setUpContents();
        };
      }(this))
    });
  };

  DVLessonListSectionAll_Select.prototype.setUpContents = function() {
    if ($('.lesson_card', this.contents).length > 0) {
      $('.lesson_card', this.contents).appendTo(this.contentsDeposit);
      this.contents.empty();
    }
    $('.lesson_card.' + this.currentCategory, this.contentsDeposit).appendTo(
      this.contents
    );
    this.lessonFinder.perform();
    $('.lesson_card input:checkbox', this.contents).on(
      'change',
      (function(_this) {
        return function() {
          return _this.onSelectedLessonsChange();
        };
      }(this))
    );
    return this.onSelectedLessonsChange();
  };

  DVLessonListSectionAll_Select.prototype.onSelectedLessonsChange = function() {
    var cnt, cntTotal, strCnt;
    cnt = $('.lesson_card input:checked').length;
    cntTotal = $(".lesson_card input[type='checkbox']").length;
    strCnt =
      '<span class="value">' +
      cnt.toString() +
      '/' +
      cntTotal.toString() +
      '</span>';
    if (cnt === 1) {
      this.selectedLessonCounter.html(
        this.selectedLessonCounterMessages['one'].replace('1', strCnt)
      );
    } else {
      this.selectedLessonCounter.html(
        this.selectedLessonCounterMessages['other'].replace('0', strCnt)
      );
    }
    return this.updateCategoryTabsWithCount();
  };

  DVLessonListSectionAll_Select.prototype.updateCategoryTabsWithCount = function() {
    return $('#category_tabs li').each(function(i, tab) {
      var category, counterEl;
      tab = $(tab);
      category = tab.attr('data-category');
      counterEl = tab.find('.value');
      return counterEl.text(
        $('.lesson_card.' + category + ' input:checked').length.toString() +
          ' / ' +
          $('.lesson_card.' + category).length.toString()
      );
    });
  };

  return DVLessonListSectionAll_Select;
}(DVLessonListSectionAll));
