/*globals $ */
var Popover = function(config) {
  this.initialize(config);
};

Popover.prototype =  {
  initialize: function(config) {
    this.$el = $(config.target);
    this.el = this.$el.get(0);

    this.closeButton = this.$el.find('.popover-close');
    this.contentView = this.$el.find('.popover-content');
    var self = this;
    this.events = {
      onResize: function() { self._onResize.apply(self, arguments); },
      onBlur: function() { self._onBlur.apply(self, arguments); },
      onClose: function() { self.close.apply(self, arguments); }
    };

    this.bindAll();
  },

  bindAll: function() {
    $(window).bind('resize', this.events.onResize);
    this.closeButton.click(this.events.onClose);
  },

  unBindAll: function() {
    $(window).unbind('resize', this.events.onResize);
  },

  // Close the UI, reset the contents and release any temporay listeners
  close: function() {
    $(document).unbind('click', this.events.onBlur);
    this.hide();
    this.reset();
  },

  remove: function() {
    this.unBindAll();
  },

  // Hide the UI
  hide: function() {
    this.$el.hide();
  },

  // Show the UI and temporarily listen for any document clicks
  show: function() {
    $(document).bind('click', this.events.onBlur);
    this.$el.show();
  },

  // Fill the popover with the given contents and display the UI over the given opener
  // @params [String] content to insert into the HTML of the popover content
  // @params [$] jQuery object to display the popover... over
  pop: function(content, opener) {
    this.opener = opener;
    this.content = content;

    this.render();
    this._positionOverOpener();
  },

  reset: function() {
    this.opener = null;
  },

  render: function() {
    this.contentView.html(this.content);

    return this.el;
  },

  _onBlur: function(e) {
    if(!$.contains(this.el, e.target)) {
      this.close();
    }
  },

  _onResize: function() {
    this._positionOverOpener();
  },

  // Position to UI at the center of the bottom edge of the opener
  _positionOverOpener: function() {
    if(this.opener && this.opener.is(':visible')) {
      var offset = this.opener.position();
      var center = offset.left + (this.opener.width() / 2);

      var position = {
         top: offset.top + this.opener.outerHeight(true) + (this.$el.height() / 2),
         left: center
      };

      this.$el.css(position);
      this.show();
    }
    else {
      this.hide();
    }
  }
};

export default Popover
