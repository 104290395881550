/*
 Davinci JS code is legacy and should be replaced as we go.
*/
import DVOrientation from './davinci/orientation'
import { sendAnalyticsEvent } from './helpers/yga'

var initMenu, initOrientation, initSelect, lessonList;

$(() => {
  var searchTracked;
  window.DV_COURSE_ID = $('#course_guid').length ? $('#course_guid').val() : '0';

  searchTracked = false;
  return $(document).on('user:search', function(e, query) {
    if (searchTracked === false && query !== '') {
      sendAnalyticsEvent('Form', 'Search', '');
      return (searchTracked = true);
    }
  });
});

lessonList = null;

(function() {
  var i, lastTime, len, vendor, vendors;
  lastTime = 0;
  vendors = ['ms', 'moz', 'webkit', 'o'];
  for (i = 0, len = vendors.length; i < len; i++) {
    vendor = vendors[i];
    if (window.requestAnimationFrame) {
      continue;
    }
    window.requestAnimationFrame = window[vendor + 'RequestAnimationFrame'];
    window.cancelAnimationFrame =
      window[vendor + 'CancelAnimationFrame'] ||
      window[vendor + 'CancelRequestAnimationFrame'];
  }
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function(callback, element) {
      var currTime, id, timeToCall;
      currTime = new Date().getTime();
      timeToCall = Math.max(0, 16 - (currTime - lastTime));
      id = window.setTimeout(function() {
        return callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };
  }
  if (!window.cancelAnimationFrame) {
    return (window.cancelAnimationFrame = function(id) {
      return clearTimeout(id);
    });
  }
})
