import Popover from '../ui/popover'
import Tabs from '../ui/tabs'
import DebugWidget from '../ui/debugWidget'
import { DVLessonListSectionAll } from '../../davinci/lesson_list_section_all'
import DVLessonListSectionLearningPath from '../../davinci/lesson_list_section_learning_path'

export default () => {
  var popover = new Popover({target: $('.lessons-popover')});

  var courseGuid = $('#course_guid').val();

  var tabNames = ['incomplete_units', 'adaptive', 'focus', 'completed', 'complete_units', 'all','learning_path']
  var loaded = []

  var tabs = new Tabs({
    scope: 'course-menu',
    select: function(name) {
      if(_.contains(tabNames, name) && !_.contains(loaded, name)) {
        $.get('/' + courseGuid + '/lessons/' + name, function(data) {
          $('#section_' + name).html(data);

          new DebugWidget('#section' + name).applyToLessons();
          switch(name) {
            case 'all':
              new DVLessonListSectionAll($("#section_all")).show();
              break
            case 'learning_path':
              new DVLessonListSectionLearningPath($("#section_learning_path")).show()
              break
            default:
          }

          loaded.push(name)
        });
      }
    }
  });

  tabs.selectCurrent();

  $(document).bind('ajaxStart lessonsFiltered', function() {
    popover.close();
  });

  $(document).bind('ajaxComplete', function() {
    $('.lesson_card a.info').click(function(e) {
      e.preventDefault();
      e.stopPropagation();

      const info = $(this);
      const lessonCard = info.parents('.lesson_card').first();
      const content = lessonCard.find('.meta .info').html();

      popover.pop(content, info);
    })
  })
}
