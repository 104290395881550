const ANIMATION_DURATION = 200
const FINAL_WIDTH = '15em'

let _input
const input = () => _input = _input || $('.search input')

const onClickOff = (e) => {
  if(e.target != input()[0]) {
    hide()
  }
}

const show = () => {
  if(!input().hasClass('active')) {
    input().addClass('active')
    input().animate({
      width: FINAL_WIDTH
    }, ANIMATION_DURATION, () => $(document).on('click.search', onClickOff))
  }
}

const hide = () => {
  input().animate({
    width: 0
  }, ANIMATION_DURATION, () => {
    input().removeClass('active')
    $(document).off('click.search')
  })
}

export default () => {
  input().click(show)

  $(document).on('search:close', hide)
}