var DVOrientation;

DVOrientation = (function() {
  function DVOrientation(elm) {
    this.element = elm;
    this.next = $('a.next');
    this.prev = $('a.prev');
    this.sections = $('.section');
    this.currentSectionIndex = 0;
    this.sectionWidth = $(this.sections[0]).width();
    this.init();
  }

  DVOrientation.prototype.init = function() {
    this.initSections();
    this.initNavItems();
    this.initPrevNext();
    return this.updateNav();
  };

  DVOrientation.prototype.initSections = function() {
    var css, i, j, ref, results, section, sectionHeader;
    results = [];
    for (
      i = j = 0, ref = this.sections.length;
      0 <= ref ? j < ref : j > ref;
      i = 0 <= ref ? ++j : --j
    ) {
      section = $(this.sections[i]);
      sectionHeader = $('.section_header', section);
      sectionHeader.text((i + 1).toString() + '. ' + sectionHeader.text());
      css = {
        position: 'absolute',
        left: this.sectionWidth * i
      };
      results.push(section.css(css));
    }
    return results;
  };

  DVOrientation.prototype.initNavItems = function() {
    var a, i, j, nav, navItem, ref;
    nav = $('ul.nav');
    for (
      i = j = 0, ref = this.sections.length;
      0 <= ref ? j < ref : j > ref;
      i = 0 <= ref ? ++j : --j
    ) {
      navItem = $(document.createElement('li'));
      navItem.data('index', i);
      a = $(document.createElement('a'));
      a.text(i.toString());
      navItem.append(a);
      nav.append(navItem);
    }
    return $('ul.nav li').on(
      'click',
      (function(_this) {
        return function(event) {
          navItem = $(event.target).closest('li');
          return _this.showSection(navItem.data('index'));
        };
      }(this))
    );
  };

  DVOrientation.prototype.initPrevNext = function() {
    this.prev.on(
      'click',
      (function(_this) {
        return function(event) {
          return _this.showSection(_this.currentSectionIndex - 1);
        };
      }(this))
    );
    return this.next.on(
      'click',
      (function(_this) {
        return function(event) {
          return _this.showSection(_this.currentSectionIndex + 1);
        };
      }(this))
    );
  };

  DVOrientation.prototype.showSection = function(index) {
    var distance, duration;
    distance = Math.abs(index - this.currentSectionIndex);
    duration = Math.floor(distance * 0.5 * 320);
    this.currentSectionIndex = index;
    this.updateNav();
    return $('#section_container').animate(
      {
        left: this.sectionWidth * index * -1
      },
      duration,
      (function(_this) {
        return function() {};
      }(this))
    );
  };

  DVOrientation.prototype.updateNav = function() {
    var navItems;
    navItems = $('ul.nav li');
    navItems.removeClass('current');
    $(navItems[this.currentSectionIndex]).addClass('current');
    if (this.currentSectionIndex < 1) {
      this.prev.hide();
    } else {
      this.prev.show();
    }
    if (this.currentSectionIndex >= this.sections.length - 1) {
      return this.next.hide();
    } else {
      return this.next.show();
    }
  };

  return DVOrientation;
}());

export default DVOrientation
