/*globals $ */
var Tabs = function(config) {
  this.initialize(config);
};

Tabs.prototype = {
  initialize: function(config) {
    this.config = config;

    this.buttons = $('a' + this._scope());
    this.sections = $('div' + this._scope());

    this.bind();
  },

  _scope: function() {
    return "[data-tab^='" + this.config.scope + "']";
  },

  bind: function() {
    var self = this;

    this.buttons.click(function(e) {
      e.stopPropagation();

      self.select($(this));
    });
  },

  unbind: function() {
    this.buttons.off('click');
  },

  select: function(tab) {
    this.sections.hide();
    this.buttons.removeClass('selected');

    tab.addClass('selected');
    var name = tab.attr('data-tab');
    var selector = "[data-tab='" + name + "']";
    var section = this.sections.filter(selector).first();

    Cookies.set(this.config.scope + '-tabs:selected', name);

    section.show();

    if(this.config.select) {
      this.config.select(name.replace(this.config.scope + ":", ''));
    }
  },

  buttonByName: function(name) {
    var selector = "[data-tab='" + name + "']";
    return this.buttons.filter(selector)[0];
  },

  selectCurrent: function() {
    // TODO: from cookie or the first tab
    var button,
        selectedTab = Cookies.get(this.config.scope + '-tabs:selected');

    if(selectedTab) {
      button = this.buttonByName(selectedTab);
    }

    if(button === null || button === undefined) {
      button = this.buttons[0];
    }

    this.select($(button));
  }
};

export default Tabs
