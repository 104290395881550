import $ from 'jquery'
import _ from 'underscore'


function search(e) {
  const results = $('#search_results')
  const path = [DV_COURSE_ID, 'lessons', 'search'].join('/')
  const keywords = e.target.value

  if(keywords.length > 0) {
      openSearch(results);
      $.get(`/${path}?keywords=${keywords}`, (response) => {
        results.html(response);
        activateClose(results);
        $('#search_results h1 span').text(keywords);
    })
  }
  else {
    closeSearch(results)
  }
}

function closeSearch (results) {
    results.fadeOut();
    $('#lesson_list').fadeIn();
    $('#course_header').fadeIn();
    results.html('')
    $(document).trigger('search:close')
}

function openSearch (results) {
    results.fadeIn();
    $('#lesson_list').fadeOut();
    $('#course_header').fadeOut();
}

function activateClose (results) {
  $('#search_results #close-icon').on("click", () => {
    $('.search input').val('');
    closeSearch(results);
  });
}

export default () => {
  $('#keywords').on('keydown', _.debounce(search, 100))
}
