Array.prototype.sortOn = function() {
  var args, dup;
  dup = this.slice();
  if (!arguments.length) {
    return dup.sort();
  }
  args = Array.prototype.slice.call(arguments);
  return dup.sort(function(a, b) {
    var prop, props;
    props = args.slice();
    prop = props.shift();
    while (a[prop] === b[prop] && props.length) {
      prop = props.shift();
    }
    if (a[prop] === b[prop]) {
      return 0;
    } else if (a[prop] > b[prop]) {
      return 1;
    } else {
      return -1;
    }
  });
};